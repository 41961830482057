@font-face {
  font-family: "Baker Signet BT W03 Regular";
  src: url("https://db.onlinewebfonts.com/t/5b14046606d3ccf49341df9d4bf7a19e.eot");
  src: url("https://db.onlinewebfonts.com/t/5b14046606d3ccf49341df9d4bf7a19e.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5b14046606d3ccf49341df9d4bf7a19e.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/5b14046606d3ccf49341df9d4bf7a19e.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/5b14046606d3ccf49341df9d4bf7a19e.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/5b14046606d3ccf49341df9d4bf7a19e.svg#Baker Signet BT W03 Regular")
      format("svg");
}

@font-face {
  font-family: "Neuzeit Grotesk Regular";
  src: url("https://db.onlinewebfonts.com/t/24ee22b1aca25a7d83f28d63c4093f8e.eot");
  src: url("https://db.onlinewebfonts.com/t/24ee22b1aca25a7d83f28d63c4093f8e.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/24ee22b1aca25a7d83f28d63c4093f8e.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/24ee22b1aca25a7d83f28d63c4093f8e.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/24ee22b1aca25a7d83f28d63c4093f8e.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/24ee22b1aca25a7d83f28d63c4093f8e.svg#Neuzeit Grotesk Regular")
      format("svg");
}

@font-face {
  font-family: "Iskry Regular";
  src: url("https://db.onlinewebfonts.com/t/c0c7e262b07e8f7d2bd87777a47e362f.eot");
  src: url("https://db.onlinewebfonts.com/t/c0c7e262b07e8f7d2bd87777a47e362f.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/c0c7e262b07e8f7d2bd87777a47e362f.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/c0c7e262b07e8f7d2bd87777a47e362f.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/c0c7e262b07e8f7d2bd87777a47e362f.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/c0c7e262b07e8f7d2bd87777a47e362f.svg#Iskry Regular")
      format("svg");
}

:root {
  --black: #242422;
  --white: #fdfbf6;
  --secondary-color: #969188;
  --btn-primary: #f5efe5;
}

body {
  background-color: var(--white);
  color: var(--black);
  font-family: "Neuzeit Grotesk Regular";
  font-weight: 400;
}

h1 {
  font-family: "Iskry Regular";
}

::selection {
  background: var(--black);
  opacity: 1;
  color: var(--white);
}

.secondary-color {
  color: var(--secondary-color);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: var(--black);
  transition: 0.5s;
  &:hover {
    color: var(--secondary-color);
  }
}
